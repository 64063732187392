import React from 'react'
import Img from "gatsby-image";
import { Container} from '../UI/Grid/Grid';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';
import classes from './Clients.module.scss';

export default ({data}) => {

  const clientsLogo =  data.allFile.edges.map( ({node}, i) => (
      <Slide index={node.id} >
      <Img
        fluid={node.childImageSharp.fluid}
        key={node.id}
        alt={node.name.replace(/-/g, ' ').substring(2)}
      />
      </Slide>
  ));

  return (
    <section id="ourclients" className="pt-0">
      <h1 className={`mb-5 pb-5`}>
      من بين عملائنا الداعمين
      </h1>
      <Container>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={19}
          isPlaying={true}
          interval={1000}
          visibleSlides={3}
          infinite={true}
          playDirection={'backword'}
          className={classes.Carousel}
        >
          <Slider>
            {clientsLogo}
          </Slider>

        </CarouselProvider>
      </Container>
    </section>
  )
}
