import React from 'react';
import { Container, ContainerFluid, Row, Col } from '../UI/Grid/Grid';
import { FaChalkboardTeacher, FaSearchDollar } from 'react-icons/fa';
import { MdAccountBalance, MdGavel, MdBorderColor } from "react-icons/md";
import { GoLaw } from "react-icons/go";

import * as classes from './Services.module.scss';

const Services = () => {

  const services = [
    {
      title: "المراجعة القانونية ",
      desc: "تطوير الاستراتيجيات القانونية والسياسات العامة للمؤسسة و المراجعة القانونية لمشروعات اللوائح  الداخلية واللوائح التنظيمية ",
      Icon: MdBorderColor
    },
    {
      title: "علاقات حكومية",
      desc: "مثيل منظمات المجتمع المدني والمؤسسات المتعاونه معها امام وزارة التضامن الاجتماعي و إنهاء كافة المعاملات القانونية اللازمه للحصول على التراخيص والتصاريح اللازمة لإتمام العمل"  ,
      Icon: MdAccountBalance
    },
    {
      title: "المناقصات و التعاقدات ",
      desc: "اعداد ومتابعه الدورة المستندية والإجرائية وكراسات الشروط للمناقصات والمزايدات ومتابعة الاجراءات اللازمة للحصول على الخدمات او شراء المستلزمات و المعدات و تقديم التوجيه والمشورة القانونية في و المعدات و تقديم التوجيه والمشورة القانونية في ",
      Icon: MdGavel
    },
    {
      title: "القضايا و المنازعات ",
      desc: "لعمل على دراسة كافة القضايا المتعلقة بالمؤسسة ومتابعة المنازعات و تمثيل المؤسسة في جميع القضايا التي ترفع منها أو عليها أمام جميع المحاكم على اختلاف درجاتها وتمثيلها أمام لجان التحكيم والطعن",
      Icon: GoLaw
    },
    {
      title: "التطوير و التدريب ",
      desc: "تقوم مجموعتنا بتقديم خدمات تدريبة و تنظيم ورش عمل للعديد من الجهات حول الادارة القانونية للجمعيات ومنظمات المجتمع المدني والقوانين ذات الصله ",
      Icon: FaChalkboardTeacher
    },
    {
      title: "التدقيق المالي ",
      desc: "توفير محاسبين ومراجعين ماليين من اكبر المكاتب المتخصصه في حسابات المشروعات والادارة المالية للمؤسسات الاهليه لمراجعه و تدقيق المستخلصات و حسابات حصر الاعمال والبنود المستجده ",
      Icon: FaSearchDollar
    }
  ]

  let servicesList = "";


  servicesList = services.map( (Service, index) => (
    <Col key={Service.title} className={` col-12 col-lg-6 mt-5`}>
      <ContainerFluid
        data-sal="slide-up"
        data-sal-duration='500'
        data-sal-easing="ease-out">
        <Row className="mt-3">
          <Col className="text-lg-left pb-3 pb-lg-0 col-12 col-lg-3">
            <Service.Icon size={36} className={classes.ServiceIcon}/>
          </Col>
          <Col className="col-12 col-lg-9">
            <h4 className={classes.ServiceHeader}>{Service.title}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={`pr-lg-4 mt-3`}>
              {Service.desc}
            </p>
          </Col>
        </Row>
      </ContainerFluid>
    </Col>
  ))


  return (
    <section id="services">
      <Container className={`text-center`}>
        <Row>
          <Col>
          <h1 className={classes.Header}>
            خدماتنا            
          </h1>
            <p>
            نسعى سعياً جاداً لتقديم أفضل الخدمات القانونية والادرية والمالية وتوفير حماية قانونية متكاملة من خلال فريق العمل من مستشارين قانونيين ومراجعين اداريين و ماليين ومحاسبين قانونيين متخصصين .
            </p>
          </Col>
        </Row>
        <Row className='mt-5'>
        {servicesList}
        </Row>
      </Container>
    </section>
  )
}

export default Services;
