import React from 'react';
import { Container, Row, Col } from '../UI/Grid/Grid';
import * as classes from './OurTeam.module.scss';

const OurTeam = () => {

  return (
    <section id="ourteam" className={classes.Background}>
      <h1 className="text-center pb-5">
        تعرف علي فريق العمل 
      </h1>
      <Container>
        <Row>
          <Col className={`${classes.BioImg} col-12  col-lg-6 mt-5 `} >
          </Col>
          <Col className={`${classes.BioDesc} col-12 order-lg-first col-lg-6 mt-5 `} >
            <div className="pl-lg-5 pb-4 text-center text-md-right">
              <h3 className={classes.BioTitle}>
                عاصم الخولي
              </h3>
              <p className="mb-5">
              مدير مجموعة الخولى القانونية
              </p>
              <p>
              المحامي بالنقض، المستشار قانوني لمنظمات المجتمع المدني والشركات
              داخل وخارج مصر، عضو مجلس ادارة الاتحاد العام للجمعيات, مستشار
              تحكيم و مسجل كوكيل براءات اختراع و تسجيل علامات تجارية ، خبير علاقات
              دبلوماسية وقنصلية.
              </p>
              <p>
                كما يقوم أ. عاصم الخولي بالقاء مُحاضرات وتنظيم ورش عمل حول
                الادارة القانونية للجمعيات ومنظمات المجتمع المدني والقوانين ذات الصله.
              </p>
              <p>
                و يقوم الخولي بالعديد من الاعمال التطوعيه فهو عضو مجلس ادارة الاتحاد
                العام للجمعيات بمصر بموجب قرار رئيس الجمهورية رقم 213 لسنة 2013م
                و هو عضو اللجنة القانونية والتظلمات بالاتحاد العام للجمعيات
                و عضو مجلس امناء مؤسسة طابة للأبحاث والاستشارات التنمويه
                و نائب رئيس مجلس ادارة جمعية سنابل للأعمال الخيرية.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )

}

export default OurTeam;

// <Col className={`${classes.Thumbnail} col-12 col-md-5 col-lg-4 mt-5 align-self-center`}>
// <div className="d-flex flex-column h-100">
//   <div>
//     <img src={bio} alt="..." className={`${classes.Thumbnail} img-fluid`} />
//   </div>
// </div>
// </Col>

//
// <Container>
//   <Row className={`${classes.BioContainer} d-flex flex-row`}>
//     <Col className={`  align-self-start col-12 col-md-5 mt-5`}>
//       <h3 className={classes.BioTitle}>
//         عاصم الخولي
//       </h3>
//       <p className="mb-5">
//         مدير مكيب الخولي
//       </p>
//       <p>
//       المحامي بالنقض، المستشار قانوني لمنظمات المجتمع المدني والشركات
//       داخل وخارج مصر، عضو مجلس ادارة الاتحاد العام للجمعيات, مستشار
//       تحكيم و مسجل كوكيل براءات اختراع و تسجيل علامات تجارية ، خبير علاقات
//       دبلوماسية وقنصلية.
//       </p>
//       <p>
//         كما يقوم أ. عاصم الخولي بالقاء مُحاضرات وتنظيم ورش عمل حول
//         الادارة القانونية للجمعيات ومنظمات المجتمع المدني والقوانين ذات الصله.
//       </p>
//       <p>
//         و يقوم الخولي بالعديد من الاعمال التطوعيه فهو عضو مجلس ادارة الاتحاد
//         العام للجمعيات بمصر بموجب قرار رئيس الجمهورية رقم 213 لسنة 2013م
//         و هو عضو اللجنة القانونية والتظلمات بالاتحاد العام للجمعيات
//         و عضو مجلس امناء مؤسسة طابة للأبحاث والاستشارات التنمويه
//         و نائب رئيس مجلس ادارة جمعية سنابل للأعمال الخيرية.
//       </p>
//     </Col>
//     <Col className="col-1">
//     </Col>
