import React from 'react';
import { Container, Row, Col } from '../UI/Grid/Grid';
import classes from './ContactUs.module.scss';
import { FaMapMarkedAlt, FaEnvelopeOpenText, FaPhone } from "react-icons/fa";
import Form from '../UI/Form/Form';

const ContactUs = () => {

  return (
    <section id="contactus" className={classes.Background}>
      <Container>
        <Row>
          <Col className={` col-12 col-lg-6 pt-lg-4`}>
            <h1 className={`${classes.Header} text-right pb-5`}>
            تواصل معنا
            </h1>
            <div className={`${classes.BorderLine}`}>
              <div className={`text-right mb-0 d-flex`}>
                <span className={`${classes.Icon} text-right pb-3 pt-3`}><FaMapMarkedAlt size={42} /></span>
                <p className={`mr-5 my-auto pb-3 pt-3`}>
                344 شارع الهرم, العمرانية, الجيزة, جمهورية مصر العربية
                </p>
              </div>
            </div>
            <div className={`${classes.BorderLine}`}>
              <div className={`text-right mb-0 d-flex`}>
                <a href="mailto:someone@yoursite.com" className={`${classes.Icon} text-right pb-3 pt-3`}><FaEnvelopeOpenText size={42} /></a>
                <p className={`mr-5 my-auto pb-3 pt-3`}>
                  elkholy@elkholylawfirm.com
                </p>
              </div>
            </div>
            <div>
              <div className={`text-right mb-0 d-flex`}>
                <span className={`${classes.Icon} text-right pb-3 pt-3`}><FaPhone size={42} /></span>
                <p className={`${classes.DirReverse} mr-5 my-auto pb-5 pt-3`}>
                  +201117745552
                  <br/>
                  +237810045
                </p>
              </div>
            </div>
          </Col>
          <Col className={` col-12 col-lg-6 pt-4`}>
            <div className={`${classes.FormContainer} p-4`}>
              <Form />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )

}

export default ContactUs;
