import React from 'react';
import * as classes from './Video.module.scss';

const Video = ({ videoUrl, closeHandler }) => {
  return (
    <div className={classes.VidWrap}>
      <div className={classes.VidContainer}>
        <div className={classes.VidClose}></div>
        <div className={classes.VidContent}>
          <div className={classes.IframScaller}>
          <iframe src={videoUrl} className={classes.Iframe} allowFullScreen="" frameBorder="0" ></iframe>
          </div>
        </div>
      </div>
      <button onClick={closeHandler} className={classes.VidClose} title='Close (Esc)' type='button'>x</button>
    </div>
  );
};

export default Video;
