import React from 'react';
import {Link} from 'gatsby';
import { Container, Row, Col } from '../UI/Grid/Grid';
import { ButtonCTA } from '../UI/Button/Button';
import * as classes from './About.module.scss';

const About = () => (
  <section id="aboutus" className={classes.Background}>
    <Container className={`text-center`}>
      <Row>
        <Col>
          <h2 className={`${classes.Header} text-bold`}>
          مستشاري منظمات المجتمع المدني 
          </h2>
          <p className={`${classes.Desc} ml-3 mr-3 mt-5 mb-5`}>
          تأسست مجموعة الخولى القانونية لاعمال المحاماة والاستشارات القانونية والمالية والادارية
في عام 2000م و للمكتب خبرات تراكمية في العديد من فروع القانون وبشكل متخصص في قوانين تنظيم ممارسة العمل الاهلي والقوانين ذات الصلة بتنمية المجتمع ؛ ويتشرف المكتب بتقديم الدعم القانوني والاداري المتكامل لأكثر من خمسة وعشرين جمعية ومؤسسة اهلية ومنظمة دولية من خلال المراجعة والتدقيق وتقديم الحلول والتغطية القانونية والمحاسبية والادارية اللازمه لاعمالها وتقييم والتدريب وورش العمل عن طريق فريق العمل بالمكتب من مستشارين ومراجعين اداريين وماليين وقانونيين ومحاسبين قانونيين متخصصين في الادارات المالية والقانونية والاجتماعية للجمعيات والمؤسسات الاهليه.
          </p>
          <ButtonCTA className={`${classes.CTA} mt-3`}>
            <Link to="/#ourteam">
            تعرف علينا 
            </Link>
          </ButtonCTA>
        </Col>
      </Row>
    </Container>
  </section>
)

export default About;
