export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules) => {
      let isValid = false;
      let msgs = [];

    if ( !rules ) {
        return msgs;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '';
        msgs.push({rule: 'required', pass: isValid, msg: " برجاء ملأ هذا الحقل"});
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength;
        msgs.push({rule: 'minLength', pass: isValid, msg: `At least ${rules.minLength} characters`});
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength
        msgs.push({rule: 'maxLength', pass: isValid, msg: `Max length is ${rules.maxLength} characters`});
    }

    if ( rules.rows) {
      isValid = value.split("\n").length <= rules.rows;
      msgs.push({rule: 'rows', pass: isValid, msg: `${value.split("\n").length} out of ${rules.rows} max rows`});
    }

    if ( rules.email ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value )
        msgs.push({rule: 'email', pass: isValid, msg: `Valid email format`});
    }

    if ( rules.password ) {
        let pattern = /^(?=.*\d)(?!.*\s).*$/;
        isValid = pattern.test( value )
        msgs.push({rule: 'password', pass: isValid, msg: `At least one number`});

        pattern = /^(?=.*[a-zA-Z])(?!.*\s).*$/;
        isValid = pattern.test( value )
        msgs.push({rule: 'password', pass: isValid, msg: `At least one letter`});

        pattern = /^(?=.*[@#$%^&+=]).*$/;
        isValid = pattern.test( value )
        msgs.push({rule: 'password', pass: isValid, msg: `At least one special character`});
    }

    if ( rules.number ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value );
        msgs.push({rule: 'number', pass: isValid, msg: `Must be a number`});
    }

    return msgs;
}
