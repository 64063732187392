import React, { useState } from 'react';
import { checkValidity } from '../../../../utils/helpers';
import {FaRegCheckCircle, FaRegTimesCircle} from "react-icons/fa";
import { ContainerFluid } from '../../Grid/Grid';

const formValidityMessages = (messages, viewErrors, viewSuccess) => {

  let content = (messages && messages.map) ? messages.map ( msg => ( (msg.pass && viewSuccess) || (!msg.pass && viewErrors))?  (
        <div key={msg.msg} className={msg.pass? "text-success": "text-danger"}>
        {msg.pass? <FaRegCheckCircle size={24} className="ml-3"/> : <FaRegTimesCircle size={24} className="ml-3"/>}
        {msg.msg}
        <br/>
        </div>
  ) : '') : ''

  return (
    messages && messages.length > 0?
      <ContainerFluid className='mt-3 mb-3'>
        {content}
      </ContainerFluid>
    : ''
  );
}

export const Input = ({
  elementType,
  type,
  label,
  className,
  value,
  rows,
  options,
  required,
  rules,
  changed,
  placeholder,
  viewErrors,
  viewSuccess
}) => {
  const [valid, setValid] = useState(false);
  const [messages, setMessages] = useState([]);

  const onChangeHandler = ( {target} ) => {

    let valid= true;

    const validity = checkValidity (
      target.value, // value
      rules
    );
    setMessages(validity);
    validity.forEach( msg => {valid = valid && msg.pass})
    setValid(valid);
    // Pass value to parent.
    changed(target.value, valid);
  }

  const onFocusHandler = ( {target} ) => {

    const validity = checkValidity (
      target.value, // value
      rules // additional rules
    );

    setMessages(validity);
  }

  const onBlurHandler = (event) => {
    // Clear messages
    if (valid) {
      setMessages([]);
    }
  }

  let formElement= '';

  switch (elementType) {
    case 'textarea':
      formElement = <textarea
            className={`form-control ${className}`}
            type={type? type : 'text'}
            value={value}
            placeholder={placeholder}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            rows={rows}
           />
    break;

    case 'select':
      formElement = <select
              className={`form-control ${className}`}
              value={value}
              onChange={onChangeHandler}>
              {options.map(option => (
                  <option key={option.value} value={option.value}>
                      {option.displayValue}
                  </option>
              ))}
          </select>
    break;

    case 'input':
    default:
    formElement = <input
              className={`form-control ${className}`}
              type={type? type : 'text'}
              value={value}
              placeholder={placeholder}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler} />
      break;
  }
  return (
      <>
        {formElement}
        {messages? formValidityMessages(messages, viewErrors, viewSuccess) : ''}
      </>
  )
};

export default Input;
