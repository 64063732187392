import React, {useState} from 'react';
import { navigate } from 'gatsby-link';
import Input from "./Input/Input";
import { ButtonCTA } from '../Button/Button';
import * as classes from './Form.module.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = () => {


  const [formData, setFormData] = useState ({
    titile: "",
    submitText: "ارسال",
    fields: [
      {
        name: "name",
        elementType: 'input',
        type: "text",
        label: "",
        placeholder: "الاسم",
        valid: false,
        rules: {
          required: true
        }
      },
      {
        name: "email",
        elementType: 'input',
        type: "email",
        label: "",
        placeholder: "البريد",
        valid: false,
        rules: {
          required: true
        }
      },
      {
        name: "phone",
        elementType: 'input',
        type: "text",
        label: "",
        placeholder: "التليقون",
        valid: true,
        rules: {
        }
      },
      {
        name:"subject",
        elementType: 'input',
        type: "text",
        label: "",
        placeholder: "عنوان الرسالة",
        valid: false,
        rules: {
          required: true
        }
      },
      {
        name: "message",
        elementType: 'textarea',
        type: "text",
        label: "",
        placeholder: "الرسالة",
        rows: 7,
        valid: false,
        rules: {
        }
      }
    ]
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useState({});

  const inputChangedHandler = (value, valid, inputIdentifier) => {

    setState({ ...state, [inputIdentifier]: value });

    const updatedFormElement = {
        ...formData[inputIdentifier],
        value: value,
        valid: valid
    }

    const updatedForm = {
      ...formData,
      updatedFormElement
    };

    let isFormValid = true;
    for (let inputIdentifier in updatedForm) {
        isFormValid = updatedForm[inputIdentifier].valid && isFormValid;
    }
    setFormData(updatedForm)
    setIsFormValid(isFormValid);
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => {
        form.innerHTML = `<div class="form--error">Error: ${error}</div>`;
      });

    setIsSubmitted(true);
  }

  const formFields = formData.fields.map ( field => (
    <label className={classes.Label} key={field.name}>
      <Input
        className={`form-control ${classes.Input}`}
        type={field.type}
        name={field.name}
        placeholder={field.placeholder}
        elementType={field.elementType}
        label={field.label}
        value={field.value}
        required={field.required}
        rules={field.rules}
        rows={field.rows}
        changed={(value, valid) => inputChangedHandler(value, valid, field.name)}
        viewErrors={true}
        viewSuccess={false}
        />
    </label>
  ))
  return (
    <>
      {formData.titile? <h1>formData.title</h1> : ""}
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={inputChangedHandler} />
          </label>
        </p>
        {formFields}
        <p className='mb-0'>
          <ButtonCTA type="submit" disabled={!isFormValid} className={classes.Submit}>
            {formData.submitText}
          </ButtonCTA>
        </p>
      </form>
    </>
  )
}

export default Form;


// import React from "react";
// import { Formik, Field } from "formik";
// import { ButtonCTA } from '../Button/Button';
// import * as classes from './Form.module.scss';
//
// const formData = {
//   title: "",
//   submitText: "ارسال",
//   fields: [
//     {
//       name: "name",
//       type: "text",
//       label: "",
//       placeholder: "الاسم",
//       required: true,
//     },
//     {
//       name: "email",
//       type: "email",
//       label: "",
//       placeholder: "البريد",
//       required: true,
//     },
//     {
//       name: "phone",
//       type: "text",
//       label: "",
//       placeholder: "التليقون",
//       required: false
//     },
//     {
//       name:"subject",
//       type: "text",
//       label: "",
//       placeholder: "عنوان الرسالة",
//       required: true
//     },
//     {
//       name: "message",
//       type: "textarea",
//       label: "",
//       placeholder: "الرسالة",
//       required: false
//     }
//   ]
// }
//
//
// const encode = (data) => {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }
//
// const Form = () => {
//
//   const formFields = formData.fields.map ( field => (
//     <p key={field.name}>
//       <label className={classes.Label}>
//         {field.label}
//         {field.type === 'textarea'?
//         <Field
//           className={`input-textarea form-control ${classes.Input}`}
//           type={field.type}
//           rows='7'
//           name={field.name}
//           id={field.name}
//           placeholder={field.placeholder}/>
//         :
//         <Field
//           className={`input form-control ${classes.Input}`}
//           id={field.name}
//           type={field.type}
//           name={field.name}
//           placeholder={field.placeholder}/>
//         }
//       </label>
//     </p>
//   ))
//
//   return (
//     <Formik
//      initialValues={{ name: '', email: '', message: '' }}
//      onSubmit={(values, { setSubmitting }) => {
//         fetch("/", {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//           body: encode({
//             'form-name': 'contact',
//             ...values,
//           }),
//         })
//           .then(() => {
//             alert('Success!')
//             setSubmitting(false)
//           })
//           .catch(error => {
//             alert('Error: Please Try Again!')
//             setSubmitting(false)
//           })
//       }}
//      render={({
//        isSubmitting,
//        handleSubmit
//      }) => (
//       <form
//         name="contact"
//         method="post"
//         action="/thanks/"
//         data-netlify="true"
//         data-netlify-honeypot="bot-field"
//         onSubmit={handleSubmit}>
//         {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
//         <Field className="input" type="hidden" name="form-name" value="contact" />
//         <p hidden>
//           <label>
//             Don’t fill this out: <Field className="input" name="bot-field"/>
//           </label>
//         </p>
//         {formFields}
//         <p className='mb-0'>
//           <ButtonCTA type="submit" className={classes.Submit}>ارسال</ButtonCTA>
//         </p>
//       </form>
//     )} />
//   );
// };
//
// export default Form;
