import React from "react";

import Layout from "../components/Layout/Layout";
import Home from '../components/Home/Home';
import About from '../components/About/About';
import Services from '../components/Services/Services';
import News from '../components/News/News';
import OurTeam from '../components/OurTeam/OurTeam';
import Clients from '../components/Clients/Clients';
import ContactUs from '../components/ContactUs/ContactUs';
import { graphql } from 'gatsby';

import SEO from "../components/seo";

import '../assets/global_styles/styles.scss';

const IndexPage = (props) => (
  <Layout>
    <SEO title="الرئيسية" />
    <Home />
    <About />
    <Services />
    <News />
    <OurTeam />
    <Clients data={props.data}/>
    <ContactUs />
  </Layout>
)

export default IndexPage

// export default Clients

export const query = graphql`
  query ImagesQuery {
      # the filter is usefull if you have multiple source-filesystem instances
      # the name "images" is set in the gatsby-config
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|(webp)/"}, relativeDirectory: {eq: "clients_logo"}}) {
      edges {
        node {
            name,
            childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
          }
        }
    }
  }
}
`;
