import React, {useState} from 'react';
import { Container, Row, Col } from '../UI/Grid/Grid';
import Video from '../UI/Video/Video';
import * as classes from './News.module.scss';
import playIcon from '../../assets/images/play_icon.png';
import news2 from '../../assets/images/lec2.jpeg';
import youm7 from '../../assets/images/logoyoum7.png';
// import dailyNews from '../../assets/images/daily_news.png';
import tadamun from '../../assets/images/tadamun.jpg';
import tadamun_lec from '../../assets/images/tadamun_lec.jpg';
import auc from '../../assets/images/auc.jpg';
import elkholy_jan2021 from '../../assets/images/elkholy_jan2021.jpg';
import elkholy_feb2021 from '../../assets/images/elkholy_feb2021.jpg';
import elkholy_feb2021_2 from '../../assets/images/elkholy_feb2021_2.jpg';
import elkholy_aug2021 from '../../assets/images/elkholy_aug2021.jpg';
import elkholy_oct2019 from '../../assets/images/elkholy_oct2019.jpg';
import elkholy_aug2022 from '../../assets/images/elkholy_aug2022.jpg';
import alakhbar from '../../assets/images/alakhbar.jpg';
import elkholy_feb2023 from '../../assets/images/elkholy_feb2023.jpg';
import elkholy_dec2018 from '../../assets/images/elkholy_dec2018.jpg';
import elkholy_jan2023 from '../../assets/images/elkholy_jan2023.png';
import akhbarelyum from '../../assets/images/akhbarelyum.png';
import elkholy_feb2021_3 from '../../assets/images/elkholy_feb2021_3.jpg';
import elkholy_sep2023 from '../../assets/images/elkholy_sep2023.jpg';


const News = () => {

  /*
  * Prepare news list
  */
  const news = [
    {
      img: akhbarelyum,
      title: "جريدة اخبار اليوم - 26 سبتمبر 2023",
      desc: " المشاركة بالحوار الوطني, ترتيب الأولويات والسماح بمذيد من التيسيرات الضريبية  ",
      link: "https://m.akhbarelyom.com/news/NewDetails/3891209/1/%D8%A7%D9%84%D8%AC%D9%85%D8%B9%D9%8A%D8%A7%D8%AA-%D8%A7%D9%84%D8%A3%D9%87%D9%84%D9%8A%D8%A9-%D8%A7%D9%82%D8%AA%D8%AD%D8%A7%D9%85-%D8%A7%D9%84%D9%85%D9%84%D9%81-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B3%D9%89-%D9%88%D8%A7"
    },
    {
      img: elkholy_feb2023,
      title: " ورشة عمل -  21 فبراير 2023 ",
      desc: " ورشة عمل للتعريف بقانون تنظيم ممارسة العمال الأهلي بأكاديمية التدريب بهيئة الاستثمار ", 
    },
    {
      img: alakhbar,
      title: " جريدة الاخبار - 28 فبراير 2023 ",
      desc: " عاصم الخولي, الاعفاء من الضرائب والرسوم يسهل أداء دورنا الوطني ",
    },
    {
      img: elkholy_jan2023,
      title: " محاوله لتشبيك المجتمع المدني - 14 يناير 2023 ",
      desc: "محاوله لتشبيك المجتمع المدني علي برنامج تحيا مصر بالقناه الاولى المخرج عمرو سعيد",
      link: "https://m.facebook.com/story.php?story_fbid=pfbid0XEtTYz7oteB17MVUhxyMPDApXf8cwWyL2EZZP5zb1U5KXrjmdJGtSW48GiHiosfql&id=1291708438&sfnsn=scwspwa&mibextid=6aamW6"
    },
    {
      img: elkholy_sep2023,
      title: " جريدة الاخبار - 27 سبتمبر 2022 ",
      desc: " مقال ترتيب الأولويات والسماح بمزيد من التيسيرات الضريبية ",
    },
    {
      img: elkholy_aug2022,
      title: " برنامج تحيا مصر القاناه الأولى الفضائية - 19 أغسطس 2022       ",
      desc: " برنامج تحيا مصر القاناه الأولى الفضائية المستشار عاصم الخولى عضو مجلس إدارة الإتحاد العام للجمعيات والمؤسسات الأهلية محاوله للوصول لمزيد من التيسيرات للمؤسسات والجمعيات الاهليه ",
      link: "https://youtu.be/2SeR-fxooWY"
    },
    {
      img: elkholy_aug2021,
      title: "الفضائية المصريه - ٥ اغسطس ٢٠٢١",
      desc: "عاصم الخولي  المستشار القانوني لمنظمات المجتمع المدني و عضو مجلس اداره الاتحاد العام للجمعيات الاهلية",
      link: "https://www.youtube.com/watch?v=PaoHU0fjWnc"
    },
    {
      img: elkholy_feb2021_3,
      title: "  الرعايه الاسقفيه - 15 فبراير 2021 ",
      desc: " الرعايه الاسقفيه تنظم ورشه عمل للتعريف بقانون ممارسه العمل الاهلي ",
      link: "https://www.albawabhnews.com/4268502"
    },
    {
      img: elkholy_feb2021_2,
      title: "الفضائية المصريه - فبراير ٢٠٢١",
      desc: "عاصم الخولي يوضح اجراءات توفيق الاوضاع ببرنامج تحيا مصر علي الفضائية المصريه",
      link: "https://www.youtube.com/watch?v=DBVvgnSm_4A"
    },
    {
      img: elkholy_feb2021,
      title: "الرعاية الأسقفية - ١٥ فبراير ٢٠٢١",
      desc: "اخبار اليوم - عاصم الخولي يحاضر في ورشه عمل حول قانون الجمعيات ولائحته التنفيذيه",
      link: "https://m.akhbarelyom.com/news/newdetails/3261595/1/%D8%A7%D9%84%D8%B1%D8%B9%D8%A7%D9%8A%D8%A9-%D8%A7%D9%84%D8%A3%D8%B3%D9%82%D9%81%D9%8A%D8%A9-%D8%AA%D9%86%D8%B8%D9%85-%D9%88%D8%B1%D8%B4%D8%A9-%D9%84%D9%84%D8%AA%D8%B9%D8%B1%D9%8A%D9%81-%D8%A8%D9%82%D8%A7%D9%86%D9%88%D9%86-%D8%A7%D9%84%D8%B9%D9%85%D9%84-%D8%A7%D9%84%D8%A3%D9%87%D9%84%D9%8A-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF"
    },
    {
      img: elkholy_jan2021,
      title: "  يناير 2021 - Gerhart Center",
      desc: " عاصم الخولي مع مركز Gerhart Center Social Talks بالجامعه الامريكيه جلسه نقاشيه حول اللائحة التنفيذيه لقانون الجمعيات ",
      link: "https://www.youtube.com/watch?v=svfAwhbvmg4"
    },
    {
      img: tadamun,
      title: "وزارة التضامن - مارس ٢٠٢٠ ",
      desc: " غرفة عمليات التدخل السريع و جنرال منظمات المجتمع المدني",
      link: "https://m.facebook.com/story.php?story_fbid=2454580601321830&id=350684925044752"
    },
    {
      img: elkholy_oct2019,
      title: "  المجلس القومي للمرأه - ١٩ اكتوبر ٢٠١٩",
      desc: " عاصم الخولي يحاضر في ورشه عمل في المجلس القومي للمرأه"
    },
    {
      img: auc,
      title: "الجامعه الامريكيه  - ٥ سبتمبر ٢٠١٩",
      desc: " التعرف على قانون الجمعيات الاهليه بمصر بمقر الجامعه الامريكيه بالقاهرة"
    },
    {
      img: tadamun_lec,
      title: " الانحاد الاقليمي - اكتوبر ٢٠١٩",
      desc: "2019 مناقشة القانون الجديد للجمعيات الاهليه بالانحاد الاقليمي للجمعيات الاهليه البرنامج التأهيلي للتعريف بالقانون ١٤٩ لسنة ",
      link: "https://m.facebook.com/story.php?story_fbid=2454580601321830&id=350684925044752"
    },
    {
      img: news2,
      title: " مؤسسة مصر الخير -  ٢٢ ديسمبر ٢٠١٨ ",
      desc: " عاصم الخولي يوضح موقف قانون الجمعيات من التأسيس و الاعفائيات و المزايا في الحوار المجتمعي للجمعيات و المؤسسات الاهليه بمصر فندق ميراميسا"
    },
    {
      img: elkholy_dec2018,
      title: "مائدة النقاش المستديرة - 17 ديسمبر 2018",
      desc: " مائدة النقاش المستديرة بالجامعة الامريكية حول التعديلات المقترحة على قانون 70 لسنة 2017 نحو قانون يعكس رغبات الفاعلين",
    },
    {
      img: akhbarelyum,
      title: "اخبار اليوم - أغسطس 2019",
      desc: " عاصم الخولى : وداعاً للقانون رقم 70 لسنة 2017م واهلا بالقانون رقم 149لسنة 2019م  لخاص بتنظيم ممارسة العمل الاهلي",
      link: "https://m.akhbarelyom.com/news/newdetails/2899195/1/%D8%A8%D8%B9%D8%AF-%D8%AA%D8%B5%D8%AF%D9%8A%D9%82-%D8%A7%D9%84%D8%B3%D9%8A%D8%B3%D9%8A-%D8%B9%D9%84%D9%89-%D9%82%D8%A7%D9%86%D9%88%D9%86-"
    },
    {
      img: youm7,
      title: " اليوم السابع - 28 نوفمبر 2016",
      desc: " عاصم الخولى ممثلا للعديد من الجمعيات : اجتماع هيئة مكتب لجنة التضامن الاجتماعى بمجلس النواب مع عدد من الجمعيات الأهلية حول مشروع قانون الجمعيات الأهلية",
      link: "https://www.youm7.com/story/2016/11/28/%D8%AA%D8%B6%D8%A7%D9%85%D9%86-%D8%A7%D9%84%D8%A8%D8%B1%D9%84%D9%85%D8%A7%D9%86-%D9%84%D9%85%D9%85%D8%AB%D9%84%D9%89-%D8%A7%D9%84%D8%AC%D9%85%D8%B9%D9%8A%D8%A7%D8%AA-%D8%AD%D8%B1%D9%8A%D8%B5%D9%88%D9%86-%D8%B9%D9%84%D9%89-%D8%A7%D9%84%D8%B9%D9%85%D9%84-%D8%A7%D9%84%D8%A3%D9%87%D9%84%D9%89-%D9%81%D9%89-%D9%85%D8%B5%D8%B1/2987820?fbclid=IwAR0iS967T2lRhqZc_m_csJIZeAYTqkvtOIhwt0rNKwt0q44qukKjqX8TEhU"
    }
  ];

  let newsList = "";

  newsList = news.map ( newsItem => (
    <Col key={newsItem.title} className={`${classes.ThumbnailContainer} col-12 text-center text-md-right col-md-6 col-lg-4 mt-5 mb-5`}>
      <a href={newsItem.link} target="_blank">
        <div className={classes.ImgWrap}>
          <img
            src={newsItem.img}
            alt="..." />
        </div>
        <div>
        <p className={`${classes.ThumbnailTitle} mt-3`}>
        <small>{newsItem.title}</small>
        </p>
        <p className="mt-3">{newsItem.desc}</p>
      </div>
    </a>
    </Col>
  ))

  const [showViedo, setShowViedo] = useState(false);

  const showViedoClickHandler = () => {
    setShowViedo(!showViedo);
  }

  return (
    <section id="news" className={classes.Background}>
      {!showViedo? "" : <Video closeHandler={showViedoClickHandler} videoUrl='https://www.youtube.com/embed/-rcbq7c4GB0?start=0&rel=0' />}
      <h1 className={classes.Header}>
        فاعليات مجموعة الخولي
      </h1>
      <section className={`${classes.ImgSection}`}>
        <div className={classes.BgOverlay}></div>
        <div className={classes.TextContainer}>
          <div className={classes.TextInner}>
            <a onClick={showViedoClickHandler}>
            <img src={playIcon} alt="PLAY" className={classes.Img} />
            </a>
            <p>
            <small>
            عاصم الخولي يوضح الفرق بين الجمعية والمؤسسة | مع الناس
            </small>
            </p>
          </div>
        </div>
      </section>
      <Container>
        <h4 className={`${classes.ExtendedTitle} text-center mb-5 mt-5 pt-5`}>
        تواصلكم معنا يعني لنا الكثير ...
        </h4>
        <Row className={`justify-content-center`}>
        {newsList}
        </Row>
      </Container>
    </section>
  );
}

export default News;
