import React from 'react';
import { Link } from 'gatsby';
import { ContainerFluid, Row, Col } from '../UI/Grid/Grid';
import { ButtonCTA } from '../UI/Button/Button';
import * as classes from './Home.module.scss';

const Home = () => {

 const data = {
   title: " مجموعة الخولي القانونية",
   subTitle: "  متخصصون في قانون الجمعيات الاهلية و تنمية المجتمع",
   cta: "  لنعمل سويا"
 }

return (
  <div id="home">
    <ContainerFluid className={`${classes.Background}`}>
      <Row className={classes.CenterTypography}>
        <Col className='text-center'>
          <div className={classes.Border}></div>
              <h1 className={`text-bold ${classes.MainText}`}>
                {data.title}
              </h1>
              <p className="mt-3 pb-5">
                {data.subTitle}
              </p>
              <ButtonCTA className={`${classes.CTA} mt-3`}>
                <Link to="/#contactus">{data.cta}</Link>
              </ButtonCTA>
        </Col>
      </Row>
    </ContainerFluid>
  </div>
  )
}

export default Home;
