import React from 'react';
import * as classes from './Button.module.scss';

export const Button = (props) => (
  <button {...props} className={`btn ${props.className? props.className : ''}`}>
    {props.children}
  </button>
);

export const ButtonCTA = (props) => (
  <button {...props} className={`btn pl-5 pr-5  ${props.className? props.className : ''}`}
  >
    {props.children}
  </button>
);
